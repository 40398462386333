import { useLocation, Outlet, Link as RouterLink } from 'react-router-dom';
// material
import { Box, Link, Container, Typography, Avatar, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';

import jwt_decode from 'jwt-decode';
import Logo from '../../components/Logo';

export default function MainLayout() {
  const [userinfo, setUserinfo] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/auth/login';
      return;
    }
    try {
      const user = jwt_decode(token);
      setUserinfo(user);
    } catch (error) {
      window.location.href = '/auth/login';
    }
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ px: '50px' }}>
        <Box>
          <Box display="flex" justifyContent="flex-end" cursor="pointer" padding='20px'>
            {userinfo && <Avatar onClick={handleClick}>{userinfo.name.charAt(0)}</Avatar>}
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <Link component={RouterLink} to="/dashboard/nih" underline="none" color="#212b36">Opportunities</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link component={RouterLink} to="/dashboard/category" underline="none" color="#212b36">Category</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link component={RouterLink} to="/dashboard/train" underline="none" color="#212b36">Train</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link component={RouterLink} to="/dashboard/history" underline="none" color="#212b36">Embedding History</Link>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Outlet />
    </>
  );
}
